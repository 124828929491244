import React from 'react'

function Gdpr() {
  return (
    <div className="container">
      <article className="page-article">
        <h1>GDPR</h1>
        <p className="lead">
          Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego rozporządzenia o ochronie danych osobowych (RODO) informujemy, że:
        </p>
          <ul style={{ listStyleType: 'circle' }}>
          <li>
            Administratorem Państwa danych osobowych jest Zaven Sp. z o. o. z siedzibą przy ul. Siennej 5b, 51-349 Wrocław, Polska.
          </li>
          <li>
            Kontakt z Administratorem danych możliwy jest pod adresem
            &nbsp;
            <a
              href="mailto:gdpr@zaven.co"
              style={{ textDecoration: 'none', color: '#00B2AD' }}
            ><strong>gdpr@zaven.co</strong></a
            >
          </li>
          <li>
            Państwa dane osobowe przetwarzane będą w celu realizacji bieżącego kontaktu poprzez prowadzenie korespondencji drogą elektroniczną w procesach związanych z przedmiotem działalności Administratora.
          </li>
          <li>
            Podanie danych jest dobrowolne, ale niezbędne do realizacji powyższych celów. Pani/Pana dane będą przetwarzane do czasu ustania celu dla jakiego zostały zgromadzone.
          </li>
          <li>
            Przetwarzanie odbywać się będzie na podstawie Państwa zgody, tj. na podstawie art. 6 ust. 1 lit. (a) lub (f) RODO, jako niezbędne do celów z prawnie uzasadnionych interesów realizowanych przez Administratora.
          </li>
        </ul>
        <hr style={{ margin: '10px 0' }} />
        <p className="lead">
          In accordance with Article 13(1) and (2) of the General Data Protection Regulation (GDPR), we inform you that:
        </p>
        <ul style={{ listStyleType: 'circle' }}>
          <li>
            The Administrator of your personal data is Zaven Sp. z o. o. based at 5b Sienna Street, 51-349 Wrocław, Poland.
          </li>
          <li>
            Contact with the Administrator is possible at
            &nbsp;
            <a
              href="mailto:gdpr@zaven.co"
              style={{ textDecoration: 'none', color: '#00B2AD' }}
            ><strong>gdpr@zaven.co</strong></a
            >
          </li>
          <li>
            Your personal data will be processed in order to conduct ongoing contact through electronic correspondence in processes related to the subject of activity of the Administrator.
          </li>
          <li>
            Providing data is voluntary, but necessary to achieve the above objectives. Your data will be processed until the purpose for which it was collected ceases to exist.
          </li>
          <li>
            The processing will be done on the basis of your consent, i.e. pursuant to Article 6(1)(a) or (f) of the GDPR, as necessary for the purposes of the legitimate interests pursued by the Administrator.
          </li>
        </ul>
      </article>
    </div>
  )
}

export default Gdpr
