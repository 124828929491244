import React from 'react'
import NavBar from '../components/common/NavBar'
import Gdpr from '../components/gdpr'

const Index = () => {
  return (
    <>
      <NavBar />
      <Gdpr />
    </>
  )
}

export default Index
